<template>
  <form action>
    <div class="modal-card" style="width: 600px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <p>{{ message }}</p>
      </section>
      <footer class="modal-card-foot">
        <div class="buttons">
          <button type="button" class="button" @click="onCancel">{{ $t('form.cancel') }}</button>
          <button ref="okBtn" type="button" autofocus class="button is-primary" @click="clickedOk">{{ $t('form.ok') }}</button>
        </div>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    onOk: {
      type: Function,
      default: () => {}
    }
  },
  mounted() {
    // this.$refs.okBtn.focus();
  },
  methods: {
    clickedOk(entity) {
      this.onOk(entity);
      this.$parent.close();
    },
    onCancel() {
      this.$parent.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-card-foot {
  justify-content: flex-end;
}
</style>
